import React from "react";
import styled from "styled-components";

const FormField = styled.div`

    position: relative;
    padding: 30px 0;

    input {
        width: 70%;
        position: absolute;
        right: 0;
        padding: 5px 10px;
        border: 1px solid #b8b8b8;
        border-radius: 3px;
    }

    select {
        width: 70%;
        position: absolute;
        right: 0;
        padding: 5px 10px;
    }

    textarea {
        width: 100%;
        display: block;
        margin: 20px 0;
        padding: 10px;
        height: 250px;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        input {
            width: 100%;
            position: relative;
            right: 0;
            padding: 5px 10px;
            margin-top: 5px;
        }
    }

`

const ContactFormContainer = styled.div`
    background-color: #F9FAFE;
    width: 100%;
`;

const ContactFormWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 0 0 40px 0;

    input[type='submit'] {
        margin: ${props => props.center ? `0 auto` : ``};
        background: #EC7580;
        color: #fff;
        outline: none;
        border: none;
        border-radius: 5px;
        padding: 10px 30px 10px 30px;
        text-align: center;
        font-weight: 600;
        background-repeat: no-repeat;
        background-size: initial;
        background-position: calc(100% - 15px) center;
        display: block;
        margin: 0 auto;

        &:hover {
            background: #e7515f;
            background-position: calc(100% - 15px) center;
            transition: background 0.3s ease-in-out;
            cursor: pointer;
        }
    }
`

const ContactForm = () => {
    return (
        <ContactFormContainer>
            <ContactFormWrapper>
                <FormField>
                    Full Name
                    <input name="fullName" type="text" required/>
                </FormField>

                <FormField>
                    Email
                    <input name="email" type="email" required/>
                </FormField>

                <FormField>
                    Phone Number
                    <input name="phone" type="phone"/>
                </FormField>

                <FormField>
                    Message
                    <textarea name="message"></textarea>
                </FormField>

                <input type="submit" value="Send" />

            </ContactFormWrapper>
        </ContactFormContainer>
    )

}

export default ContactForm;