import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "./../components/footer";
import ContactForm from "./../components/ContactForm";

import IllustrationBlock from "./../components/illustration-block";

const TitleHolder = styled.div`
    background-color: #F9FAFE;
    width: 100%;
    text-align: center;
    margin: 0;

    h1 {
      margin: 0;
      padding: 50px 0 0 0;
      font-size: 2rem;
      font-weight: 500;
      font-family: 'Rufina', serif;
    }
`


const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us"/>
    <TitleHolder><h1>Contact</h1></TitleHolder>
    <ContactForm/>
    <Footer/>
  </Layout>
)

export default ContactPage
